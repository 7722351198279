<template>
    <bread-page>
        <div class="main" v-loading="loading">
            <div class="top">
                <div class="img">
                    <el-carousel height="360px">
                        <el-carousel-item v-for="(item, index) in imgs" :key="index">
                            <el-image :src="item" fit="fill" style="width: 100%; height: 100%"></el-image>
                        </el-carousel-item>
                    </el-carousel>
                </div>

                <div class="info">
                    <div class="title">{{ info.name }}</div>
                    <div class="info-item">
                        <span class="text1">采购类型：</span>
                        <span class="text2">{{ purchaseType }}</span>
                    </div>
                    <div class="info-item">
                        <span class="text1">所属领域：</span>
                        <span class="text2" v-if="info.setting">{{ info.setting.name }}</span>
                    </div>
                    <div class="info-item">
                        <span class="text1">项目预算：</span>
                        <span class="text2">{{ info.expectedPrice }}(万元)</span>
                    </div>
                    <div class="info-item">
                        <span class="text1">截止日期：</span>
                        <div class="text2">{{ formatTime(info.createdAt, 'YYYY.MM.DD') }}</div>
                    </div>
                    <div class="info-item">
                        <span class="text1">地址：</span>
                        <div class="text2">{{ info.address }}</div>
                    </div>

                    <div class="flex1"></div>

                    <div class="btn">
                        <el-button type="primary" class="icon-button" @click.prevent="connectKefu" size="small">
                            <i class="iconfont iconfont-nav_icon_xiaoxi1"></i>
                            咨询客服
                        </el-button>
                        <el-button @click.stop="postMessage" type="primary" class="icon-button" size="small">
                            <i class="iconfont iconfont-nav_icon_xiaoxi"></i>提交留言
                        </el-button>
                    </div>
                </div>
            </div>
            <!-- <div class="content" v-for="item in recommends" :key="item.id">
                <div class="recommends">
                    <div class="recommends-title">为你推荐</div>
                    <router-link
                        :to="{
                            name: 'purchaseDetail',
                            query: {
                                id: item.id
                            }
                        }"
                        class="recomend-item"
                    >
                        <el-image
                            :src="item.img"
                            style="width: 180px; height: 180px; display: block"
                            fit="cover"
                        ></el-image>
                        <span>{{ item.name }}</span>
                    </router-link>
                </div>
            </div> -->
            <div class="detail">
                <div class="detail-title">采购详情</div>
                <div class="detail-content" v-html="info.content || '暂无内容'"></div>
            </div>
        </div>
    </bread-page>
</template>

<script>
import BreadPage from '../../components/page/BreadPage.vue';
import { purchaseTypeOptions, tradingMethodOptions, purchaseItemOptions } from '../../utils/variables';
import comEvent from '../../mixins/comEvent';
export default {
    components: { BreadPage },
    mixins: [comEvent],
    data() {
        return {
            loading: true,
            info: {},
            fieldOptions: [],
            recommends: [],
            purchaseTypeOptions,
            tradingMethodOptions
        };
    },

    computed: {
        imgs() {
            if (this.info.img && this.info.img.length > 0) {
                return this.info.img;
            } else {
                return [this.getImg()];
            }
        },
        purchaseType() {
            let info = purchaseTypeOptions.find(item => {
                return item.value === this.info.purchaseType;
            });
            return info ? info.label : '';
        }
    },
    watch: {
        $route() {
            this.getFn();
        }
    },
    mounted() {
        this.getFn();
    },
    methods: {
        getFn() {
            this.loading = true;
            this.$http.get('/purchase/get/' + this.$route.query.id).then(res => {
                this.info = res;
                setTimeout(() => {
                    this.loading = false;
                }, 500);

                let info = purchaseItemOptions.find(item => {
                    return item.value === res.purchaseItem;
                });
                this.$EventBus.$emit('changePreTitle', {
                    name: info.label,
                    path: info.path || '/purchase',
                    query: {
                        purchaseItem: res.purchaseItem
                    }
                });
                this.$EventBus.$emit('changeTitle', info.label + '详情');
                document.title = info.label + '详情';
            });
            this.$http
                .get('/purchase/recommend', {
                    id: this.$route.query.id
                })
                .then(res => {
                    this.recommends = res.content;
                });
        }
    },
    created() {
        let addView = {
            id: this.$route.query.id,
            type: 'Purchase'
        };
        // console.log(addView);
        this.loading = true;
        this.$http.get('/all/addView/', addView).then(res => {
            console.log(res);
        });
    }
};
</script>

<style lang="less" scoped>
.main {
    background-color: #fff;
    padding: 22px 100px 150px;
}

.img {
    width: 360px;
}

.top {
    .flex();
    align-items: stretch;

    .info {
        flex-grow: 1;
        margin-left: 70px;
        .flex-col();

        .title {
            font-size: 24px;
            color: #000000;
            line-height: 33px;
            padding: 20px 0;
        }

        .btn {
            .icon-button {
                line-height: 44px;
                min-width: 120px;
            }
            .icon-button + .icon-button {
                margin-left: 16px;
            }
        }
    }
    padding-bottom: 60px;
}

.info-item {
    box-sizing: border-box;
    line-height: 24px;
    margin-bottom: 12px;
    .flex();

    .text1 {
        font-size: 14px;
        color: #999999;
        display: inline-block;
        min-width: 80px;
        flex-shrink: 0;
    }

    .text2 {
        font-size: 14px;
        color: @warn;
        margin-left: 6px;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
.detail {
    .detail-title {
        font-size: 16px;
        font-weight: bold;
        color: #000000;
        line-height: 22px;
        padding: 7px 0;
        border-bottom: 1px solid @bg;
    }

    .detail-content {
        font-size: 12px;
        color: #000000;
        line-height: 22px;

        /deep/ img {
            display: block;
            margin: 10px auto;
        }

        /deep/ p {
            margin-top: 20px;
        }
    }
}
.content {
    .flex();
    align-items: flex-start;
    .detail {
        flex-grow: 1;
    }
    .recommends {
        min-width: 180px;
        margin-right: 30px;
        .recommends-title {
            font-size: 16px;
            font-weight: bold;
            color: #000000;
            line-height: 22px;
            padding: 7px 0;
        }
        .recomend-item {
            margin-top: 16px;
            border: 1px solid #f5f7fa;
            width: 180px;
            overflow: hidden;
            cursor: pointer;
            display: block;
            &:hover {
                border-color: @prim;
            }
            .info > span {
                flex-shrink: 0;
            }
            .info {
                height: 44px;
                .flex();
                padding: 0 12px;
                width: 100%;
                box-sizing: border-box;
                vertical-align: middle;
                overflow: hidden;

                font-size: 14px;
                color: #000000;
                line-height: 44px;
            }
        }
        span {
            color: #000;
        }
    }
}
</style>
